import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo22() {
    // Data for Female Share % in 2018 and 2023 by Age Group
    const ageGroupData = [
        { ageGroup: '14-18', femaleShare2018: 2.00, femaleShare2023: 2.43 },
        { ageGroup: '19-21', femaleShare2018: 4.53, femaleShare2023: 4.88 },
        { ageGroup: '22-24', femaleShare2018: 5.86, femaleShare2023: 6.42 },
        { ageGroup: '25-34', femaleShare2018: 20.48, femaleShare2023: 20.93 },
        { ageGroup: '35-44', femaleShare2018: 23.13, femaleShare2023: 21.56 },
        { ageGroup: '45-54', femaleShare2018: 22.15, femaleShare2023: 21.50 },
        { ageGroup: '55-64', femaleShare2018: 17.08, femaleShare2023: 16.02 },
        { ageGroup: '65-99', femaleShare2018: 4.76, femaleShare2023: 6.24 },
    ];

    // Chart data configuration
    const chartData = {
        labels: ageGroupData.map((data) => data.ageGroup),
        datasets: [
            {
                label: 'Female Share % (2018)',
                data: ageGroupData.map((data) => data.femaleShare2018),
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Female Share % (2023)',
                data: ageGroupData.map((data) => data.femaleShare2023),
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Female Share % by Age Group (2018 vs 2023)',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 25, // Adjust the max value as needed for better visualization
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Female Share % by Age Group (2018 vs 2023)</h2>

            {/* Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Age Group Label</th>
                            <th className="px-4 py-2">Female Share % (2018)</th>
                            <th className="px-4 py-2">Female Share % (2023)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ageGroupData.map((row, index) => (
                            <tr key={index} className="border-b">
                                <td className="px-4 py-2 font-semibold">{row.ageGroup}</td>
                                <td className="px-4 py-2">{row.femaleShare2018}%</td>
                                <td className="px-4 py-2">{row.femaleShare2023}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Grouped Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
