


// // import React, { useEffect } from 'react';
// // import { Chart, registerables } from 'chart.js';

// // const ShareChart1 = () => {
// //   useEffect(() => {
// //     Chart.register(...registerables);

// //     const ctx = document.getElementById('groupedShareChart').getContext('2d');

// //     if (ctx.chart) {
// //       ctx.chart.destroy();
// //     }

// //     const groupedShareChart = new Chart(ctx, {
// //       type: 'bar',
// //       data: {
// //         labels: ['2018', '2019', '2020', '2021', '2022'],
// //         datasets: [
// //           {
// //             label: 'Women % Share',
// //             data: [30, 32, 33, 35, 37],
// //             backgroundColor: 'rgba(255, 99, 132, 0.6)',
// //             borderColor: 'rgba(255, 99, 132, 1)',
// //             borderWidth: 1,
// //           },
// //           {
// //             label: 'Men % Share',
// //             data: [70, 68, 67, 65, 63],
// //             backgroundColor: 'rgba(54, 162, 235, 0.6)',
// //             borderColor: 'rgba(54, 162, 235, 1)',
// //             borderWidth: 1,
// //           },
// //         ],
// //       },
// //       options: {
// //         responsive: true,
// //         maintainAspectRatio: false,
// //         scales: {
// //           y: {
// //             beginAtZero: true,
// //             title: {
// //               display: true,
// //               text: 'Percentage (%)',
// //             },
// //           },
// //         },
// //       },
// //     });

// //     return () => {
// //       groupedShareChart.destroy();
// //     };
// //   }, []);

// //   return (
// //     <div className="flex justify-center items-center w-[80%] h-auto"> {/* Adjusted height for centering */}
// //       <div className="chart-container justify-center items-center w-[80%] h-[50%]"> {/* Reduced width and set height */}
// //         <canvas id="groupedShareChart" className="w-[80%] h-full justify-center items-center ml-[0%]"></canvas> {/* Use full width and height */}
// //       </div>
// //     </div>
// //   );
// // };

// // export default ShareChart1;






// import React, { useEffect } from 'react';
// import { Chart, registerables } from 'chart.js';

// const ShareChart1 = () => {
//   useEffect(() => {
//     Chart.register(...registerables);

//     const canvas = document.getElementById('groupedShareChart');
//     const ctx = canvas.getContext('2d');

//     // Destroy previous chart instance if it exists to prevent duplication
//     if (ctx.chart) {
//       ctx.chart.destroy();
//     }

//     const groupedShareChart = new Chart(ctx, {
//       type: 'bar',
//       data: {
//         labels: ['2018', '2019', '2020', '2021', '2022'],
//         datasets: [
//           {
//             label: 'Women % Share',
//             data: [30, 32, 33, 35, 37],
//             backgroundColor: 'rgba(255, 99, 132, 0.6)',
//             borderColor: 'rgba(255, 99, 132, 1)',
//             borderWidth: 1,
//           },
//           {
//             label: 'Men % Share',
//             data: [70, 68, 67, 65, 63],
//             backgroundColor: 'rgba(54, 162, 235, 0.6)',
//             borderColor: 'rgba(54, 162, 235, 1)',
//             borderWidth: 1,
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//           legend: {
//             position: 'top',
//           },
//         },
//         scales: {
//           x: {
//             ticks: {
//               color: '#4B5563', // Adjust tick color for better visibility
//             },
//           },
//           y: {
//             beginAtZero: true,
//             ticks: {
//               color: '#4B5563',
//             },
//             title: {
//               display: true,
//               text: 'Percentage (%)',
//               color: '#4B5563',
//               font: {
//                 size: 14,
//               },
//             },
//           },
//         },
//       },
//     });

//     // Clean up to prevent memory leaks
//     return () => {
//       groupedShareChart.destroy();
//     };
//   }, []);

//   return (
//     <div className="flex justify-center items-center w-full h-auto p-4">
//       {/* Container to make the chart responsive */}
//       <div className="chart-container w-full max-w-4xl h-[60vh]">
//         <canvas id="groupedShareChart"></canvas>
//       </div>
//     </div>
//   );
// };

// export default ShareChart1;






import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

const ShareChart1 = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Register all necessary chart components
    Chart.register(...registerables);

    const ctx = canvasRef.current.getContext('2d');

    // Destroy previous chart instance if it exists to prevent duplication
    if (ctx.chart) {
      ctx.chart.destroy();
    }

    // Create the bar chart
    const groupedShareChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['2018', '2019', '2020', '2021', '2022'],
        datasets: [
          {
            label: 'Women % Share',
            data: [30, 32, 33, 35, 37],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Men % Share',
            data: [70, 68, 67, 65, 63],
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: '#4B5563',
              font: {
                size: 12,
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#4B5563', 
              font: {
                size: 12,
              },
            },
            title: {
              display: true,
              text: 'Year',
              color: '#4B5563',
              font: {
                size: 14,
                weight: 'bold',
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: '#4B5563',
              font: {
                size: 12,
              },
            },
            title: {
              display: true,
              text: 'Percentage (%)',
              color: '#4B5563',
              font: {
                size: 14,
                weight: 'bold',
              },
            },
          },
        },
      },
    });

    // Cleanup to prevent memory leaks
    return () => {
      groupedShareChart.destroy();
    };
  }, []);

  return (
    <div className="w-full p-4 overflow-x-auto">
      {/* Container with horizontal scroll */}
      <div className="min-w-[800px] max-w-full h-[60vh]">
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
};

export default ShareChart1;
