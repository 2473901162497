// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

// const TableNo16 = () => {
//   // Data for the table and chart
//   const raceData = [
//     { race: 'American Indian or Alaska Native Alone', share2018: 1.51, share2023: 1.55 },
//     { race: 'Asian Alone', share2018: 5.16, share2023: 5.19 },
//     { race: 'Black or African American Alone', share2018: 2.16, share2023: 2.83 },
//     { race: 'Native Hawaiian or Other Pacific Islander Alone', share2018: 1.19, share2023: 1.14 },
//     { race: 'Two or More Race Groups', share2018: 1.71, share2023: 2.10 },
//     { race: 'White Alone', share2018: 88.27, share2023: 87.17 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: raceData.map(item => item.race),
//     datasets: [
//       {
//         label: 'Share % (2018)',
//         data: raceData.map(item => item.share2018),
//         fill: false,
//         backgroundColor: 'rgba(75, 192, 192, 1)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         tension: 0.1,
//       },
//       {
//         label: 'Share % (2023)',
//         data: raceData.map(item => item.share2023),
//         fill: false,
//         backgroundColor: 'rgba(255, 99, 132, 1)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         tension: 0.1,
//       },
//     ],
//   };

//   // Line chart options
//   const options = {
//     responsive: true,
//     scales: {
//       y: {
//         beginAtZero: true,
//         min: 0,
//         max: 100,
//         ticks: {
//           callback: (value) => value + '%', // Append % sign to ticks
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Racial Share Comparison (2018 vs 2023)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Race</th>
//             <th>Share % (2018)</th>
//             <th>Share % (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {raceData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.race}</td>
//               <td>{item.share2018.toFixed(2)}%</td>
//               <td>{item.share2023.toFixed(2)}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Line Chart */}
//       <div style={{ height: '400px', width: '100%' }}>
//         <Line data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo16;









import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const TableNo16 = () => {
  // Data for the table and chart
  const raceData = [
    { race: 'American Indian or Alaska Native Alone', share2018: 1.51, share2023: 1.55 },
    { race: 'Asian Alone', share2018: 5.16, share2023: 5.19 },
    { race: 'Black or African American Alone', share2018: 2.16, share2023: 2.83 },
    { race: 'Native Hawaiian or Other Pacific Islander Alone', share2018: 1.19, share2023: 1.14 },
    { race: 'Two or More Race Groups', share2018: 1.71, share2023: 2.10 },
    { race: 'White Alone', share2018: 88.27, share2023: 87.17 },
  ];

  // Chart data
  const chartData = {
    labels: raceData.map(item => item.race),
    datasets: [
      {
        label: 'Share % (2018)',
        data: raceData.map(item => item.share2018),
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.3,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
      {
        label: 'Share % (2023)',
        data: raceData.map(item => item.share2023),
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.3,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  };

  // Line chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows better resizing
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => value + '%', // Append % sign to ticks
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: 'Percentage (%)',
          color: '#4B5563',
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Racial Share Comparison (2018 vs 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive className="mb-4">
        <thead>
          <tr>
            <th>Race</th>
            <th>Share % (2018)</th>
            <th>Share % (2023)</th>
          </tr>
        </thead>
        <tbody>
          {raceData.map((item, index) => (
            <tr key={index}>
              <td>{item.race}</td>
              <td>{item.share2018.toFixed(2)}%</td>
              <td>{item.share2023.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Line Chart with Horizontal Scroll */}
      <div className="overflow-auto">
        <div style={{ minWidth: '600px', height: '400px' }}>
          <Line data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo16;
