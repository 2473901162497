// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

// const TableNo13 = () => {
//   // Data for the table and chart
//   const raceData = [
//     { race: 'American Indian or Alaska Native Alone', change: 0.05 },
//     { race: 'Asian Alone', change: 0.03 },
//     { race: 'Black or African American Alone', change: 0.68 },
//     { race: 'Native Hawaiian or Other Pacific Islander Alone', change: -0.05 },
//     { race: 'Two or More Race Groups', change: 0.39 },
//     { race: 'White Alone', change: -1.10 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: raceData.map(item => item.race),
//     datasets: [
//       {
//         label: '% Change',
//         data: raceData.map(item => item.change),
//         fill: false,
//         backgroundColor: 'rgba(75, 192, 192, 1)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         tension: 0.1,
//       },
//     ],
//   };

//   // Line chart options
//   const options = {
//     responsive: true,
//     scales: {
//       y: {
//         beginAtZero: true,
//         min: -2, // Adjust to allow for negative values in the chart
//         max: 1,
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Percentage Change by Race</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Race</th>
//             <th>% Change</th>
//           </tr>
//         </thead>
//         <tbody>
//           {raceData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.race}</td>
//               <td>{item.change.toFixed(2)}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Line Chart */}
//       <div style={{ height: '400px', width: '100%' }}>
//         <Line data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo13;






import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const TableNo13 = () => {
  // Data for the table and chart
  const raceData = [
    { race: 'American Indian or Alaska Native Alone', change: 0.05 },
    { race: 'Asian Alone', change: 0.03 },
    { race: 'Black or African American Alone', change: 0.68 },
    { race: 'Native Hawaiian or Other Pacific Islander Alone', change: -0.05 },
    { race: 'Two or More Race Groups', change: 0.39 },
    { race: 'White Alone', change: -1.10 },
  ];

  // Chart data
  const chartData = {
    labels: raceData.map(item => item.race),
    datasets: [
      {
        label: '% Change',
        data: raceData.map(item => item.change),
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.3,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  };

  // Line chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to resize dynamically
    scales: {
      y: {
        beginAtZero: false,
        min: -2, // Adjust to allow for negative values in the chart
        max: 1,
        ticks: {
          callback: (value) => value + '%', // Append % sign to ticks
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: 'Percentage Change (%)',
          color: '#4B5563',
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Percentage Change by Race</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive className="mb-4">
        <thead>
          <tr>
            <th>Race</th>
            <th>% Change</th>
          </tr>
        </thead>
        <tbody>
          {raceData.map((item, index) => (
            <tr key={index}>
              <td>{item.race}</td>
              <td>{item.change.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Line Chart with Horizontal Scroll */}
      <div className="overflow-auto">
        <div style={{ minWidth: '600px', height: '400px' }}>
          <Line data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo13;
