import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo19() {
    // Data for the table and chart
    const data = {
        years: ['2018', '2019', '2020', '2021', '2022', '2023'],
        maleShare: [70.43, 69.64, 69.28, 68.52, 67.96, 68.01],
    };

    // Chart data configuration
    const chartData = {
        labels: data.years,
        datasets: [
            {
                label: 'Male Share %',
                data: data.maleShare,
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Employment Share % for Males (2018-2023)',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: value => `${value}%`, // Format y-axis values as percentages
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Employment Share % for Males (2018-2023)</h2>

            {/* Responsive Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Year</th>
                            {data.years.map((year, index) => (
                                <th key={index} className="px-4 py-2">{year}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b">
                            <td className="px-4 py-2 font-semibold">Male Share %</td>
                            {data.maleShare.map((value, index) => (
                                <td key={index} className="px-4 py-2">{value.toFixed(2)}%</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Responsive Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
