import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const TableNo26 = () => {
  const femaleData = [
    { ageGroup: '14-18', femaleShare: 2.43 },
    { ageGroup: '19-21', femaleShare: 4.88 },
    { ageGroup: '22-24', femaleShare: 6.42 },
    { ageGroup: '25-34', femaleShare: 20.93 },
    { ageGroup: '35-44', femaleShare: 21.56 },
    { ageGroup: '45-54', femaleShare: 21.50 },
    { ageGroup: '55-64', femaleShare: 16.02 },
    { ageGroup: '65-99', femaleShare: 6.24 },
  ];

  const chartData = {
    labels: femaleData.map(item => item.ageGroup),
    datasets: [
      {
        label: 'Female Share %',
        data: femaleData.map(item => item.femaleShare),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return value + '%'; // Adding percentage sign
          },
        },
      },
    },
  };

  return (
    <div className="container">
      <h2>Female Share Percentage Analysis</h2>
      
      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Age Group</th>
            <th>Female Share %</th>
          </tr>
        </thead>
        <tbody>
          {femaleData.map((item, index) => (
            <tr key={index}>
              <td>{item.ageGroup}</td>
              <td>{item.femaleShare.toFixed(2)}%</td> {/* Displaying with two decimal places */}
            </tr>
          ))}
        </tbody>
      </Table>
      
      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};
