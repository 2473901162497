



import React from 'react';

export default function Form() {
  return (
    <div>
      <div className="container mx-auto mt-[12rem] mb-[4.4rem] px-4 lg:px-0">
        {/* Form Section */}
        <div className="form-container bg-white rounded-xl p-6 lg:p-10 w-full md:w-[80vw] lg:w-[60vw] shadow-lg mx-auto text-center">
          <div className="no-underline">
            <h2 className="form-title text-[#1b2a49] text-xl lg:text-2xl mb-5 flex items-center justify-center gap-2">
              <i className="fas fa-file-alt text-[#1b2a49]"></i>
              <span className="no-underline">Request a Data Report</span>
            </h2>
          </div>

          <p className="form-introduction text-sm lg:text-base text-gray-700 mb-8 text-justify leading-relaxed flex items-start gap-2">
            <i className="fas fa-info-circle"></i>
            We are continuously adding comprehensive reports to assist you in making well-informed decisions. Our commitment is to provide you with the best insights, helping you understand and analyze data effectively for better decision-making.
          </p>

          <form id="reportForm" action="submit_form.php" method="POST">
            <div className="form-row flex flex-col lg:flex-row gap-5 mb-6">
              {/* Name Field */}
              <div className="form-group relative flex-1">
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  className="peer w-full p-3 border border-gray-300 rounded-md outline-none transition duration-300 placeholder-transparent"
                  placeholder="Your Name"
                />
                <label
                  htmlFor="name"
                  className="absolute left-3 top-3 text-gray-500 text-sm transition-all duration-300 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-[-10px] peer-focus:left-3 peer-focus:text-sm peer-focus:text-blue-600"
                >
                  <i className="fas fa-user"></i> Your Name
                </label>
              </div>

              {/* Email Field */}
              <div className="form-group relative flex-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="peer w-full p-3 border border-gray-300 rounded-md outline-none transition duration-300 placeholder-transparent"
                  placeholder="Your Email"
                />
                <label
                  htmlFor="email"
                  className="absolute left-3 top-3 text-gray-500 text-sm transition-all duration-300 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-[-10px] peer-focus:left-3 peer-focus:text-sm peer-focus:text-blue-600"
                >
                  <i className="fas fa-envelope"></i> Your Email
                </label>
              </div>
            </div>

            <div className="form-row flex flex-col lg:flex-row gap-5 mb-6">
              {/* Report Type Field */}
              <div className="form-group relative flex-1">
                <input
                  type="text"
                  id="reportType"
                  name="reportType"
                  required
                  className="peer w-full p-3 border border-gray-300 rounded-md outline-none transition duration-300 placeholder-transparent"
                  placeholder="Type of Report"
                />
                <label
                  htmlFor="reportType"
                  className="absolute left-3 top-3 text-gray-500 text-sm transition-all duration-300 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-[-10px] peer-focus:left-3 peer-focus:text-sm peer-focus:text-blue-600"
                >
                  <i className="fas fa-chart-bar"></i> Type of Report
                </label>
              </div>

              {/* Suggestions Field */}
              <div className="form-group relative flex-1">
                <textarea
                  id="suggestions"
                  name="suggestions"
                  rows="3"
                  required
                  className="peer w-full p-3 border border-gray-300 rounded-md outline-none transition duration-300 placeholder-transparent"
                  placeholder="Your Suggestions & Feedback"
                ></textarea>
                <label
                  htmlFor="suggestions"
                  className="absolute left-3 top-3 text-gray-500 text-sm transition-all duration-300 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-[-10px] peer-focus:left-3 peer-focus:text-sm peer-focus:text-blue-600"
                >
                  <i className="fas fa-comments"></i> Your Suggestions & Feedback
                </label>
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="submit-button bg-[#f0a500] text-blue-950 rounded-md py-3 px-5 hover:bg-yellow-600 transition duration-300 flex items-center justify-center gap-2 w-full"
            >
              <i className="fas fa-paper-plane text-blue-950"></i>
              <span>Submit Request</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
