// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo31 = () => {
//   // Data for the table and chart
//   const educationData = [
//     { educationLevel: 'Less than high school', female2018: 6482, female2023: 8241 },
//     { educationLevel: 'High school or equivalent, no college', female2018: 9686, female2023: 11301 },
//     { educationLevel: 'Some college or Associate degree', female2018: 11012, female2023: 13221 },
//     { educationLevel: "Bachelor's degree or advanced degree", female2018: 7506, female2023: 9747 },
//     { educationLevel: 'Educational attainment not available (workers < 24)', female2018: 4939, female2023: 6811 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: educationData.map(item => item.educationLevel),
//     datasets: [
//       {
//         label: 'Female Employee (2018)',
//         data: educationData.map(item => item.female2018),
//         backgroundColor: 'rgba(255, 99, 132, 0.6)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Female Employee (2023)',
//         data: educationData.map(item => item.female2023),
//         backgroundColor: 'rgba(54, 162, 235, 0.6)',
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => value, // Display exact values on the Y-axis
//         },
//       },
//     },
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw}`, // Show exact value in tooltip
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Female Employees by Education Level</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Education Level</th>
//             <th>Female Employee (2018)</th>
//             <th>Female Employee (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {educationData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.educationLevel}</td>
//               <td>{item.female2018}</td>
//               <td>{item.female2023}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div style={{ height: '400px', width: '100%' }}>
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo31;










import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo31 = () => {
  // Data for the table and chart
  const educationData = [
    { educationLevel: 'Less than high school', female2018: 6482, female2023: 8241 },
    { educationLevel: 'High school or equivalent, no college', female2018: 9686, female2023: 11301 },
    { educationLevel: 'Some college or Associate degree', female2018: 11012, female2023: 13221 },
    { educationLevel: "Bachelor's degree or advanced degree", female2018: 7506, female2023: 9747 },
    { educationLevel: 'Educational attainment not available (workers < 24)', female2018: 4939, female2023: 6811 },
  ];

  // Chart data
  const chartData = {
    labels: educationData.map(item => item.educationLevel),
    datasets: [
      {
        label: 'Female Employee (2018)',
        data: educationData.map(item => item.female2018),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Female Employee (2023)',
        data: educationData.map(item => item.female2023),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(), // Display exact values with commas
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: 'Number of Female Employees',
          color: '#4B5563',
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`, // Show value with commas
        },
        bodyFont: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Female Employees by Education Level (2018 vs 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive className="mb-4">
        <thead>
          <tr>
            <th>Education Level</th>
            <th>Female Employee (2018)</th>
            <th>Female Employee (2023)</th>
          </tr>
        </thead>
        <tbody>
          {educationData.map((item, index) => (
            <tr key={index}>
              <td>{item.educationLevel}</td>
              <td>{item.female2018.toLocaleString()}</td>
              <td>{item.female2023.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart with Horizontal Scroll */}
      <div className="overflow-auto">
        <div style={{ minWidth: '600px', height: '400px' }}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo31;
