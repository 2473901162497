
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import image from '../utahdata.jpg';
import image1 from '../utahdata1.jpg';
import image2 from '../utahdata2.jpg';
import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css'; 
import 'swiper/css/pagination'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import './HomePage.css'; // Import the CSS file
import kpi from '../KPI-DASH 2.png';
import { Link } from 'react-router-dom';

export default function HomePage() {

  const [searchInput, setSearchInput] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const navigate = useNavigate();

  // FAQ Data (Hardcoded for simplicity)
  const faqData = {
    "Employment-Trend": [
      "Share percentage Analysis of Women versus Men in Manufacturing Employment",
      "How does the overall employment trend in manufacturing reflect diversity changes between 2018 and 2023?",
      "What are the total employment trends in Utah's manufacturing sector from 2018 to 2023?",
      "How has the female employment share in the manufacturing industry changed in 2023 compared to 2018?",
      "What percentage of the total workforce in 2023 is male in Utah's manufacturing sector?",
      "What are the differences in employment counts between males and females in Utah manufacturing from 2018 to 2023?",
      "How has male employment in Utah's manufacturing sector evolved over the past year?",
      "What trends are observed in the share of female workers in the Utah manufacturing sector?",
      "What is the total count of male employees in the Utah manufacturing workforce in 2023?"

    ],
    "Age Analysis": [
      "What is the percentage share of males and females in each age group during 2023?",
      "What is the total number of workers across all age groups?",
      "What percentage of the total workforce is female across all age groups?",
      "What is the Age Profile for Women in Manufacturing versus Men?",
      "Which age group has the highest number of male workers?",
      "How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?",
      "Which age group has the highest percentage of female workers in 2023?",
      "Which age group shows the largest gender disparity in terms of employment?",
      "What is the overall percentage share of male workers in the manufacturing sector across all age groups during 2023?",
      "What age group represents the smallest share of total employment in the year 2023?",
      "What is the female-to-male ratio for each age group in manufacturing employment during 2023?",
    ],
    "Race Analysis": [
      "How does the distribution of employment share compare across different race groups in 2023?",
      "What is the ratio of employment counts for 2018 vs 2023 for each race group?",
      "What is the percentage change in employment share for each race group from 2018 to 2023?",
      "How do the employment changes across race groups compare for the years 2018 and 2023?"
    ],
    "Education Analysis": [
      "Education Profile of Women in Manufacturing Employment",
      "Education Profile of Men in Manufacturing Employment",
      "How has the percentage share of women in manufacturing changed from 2018 to 2023 across different education levels?",
      "Education Profile of Women versus Men Percentage in Manufacturing Employment",
      "Which education level saw the largest increase in female employment in the manufacturing sector between 2018 and 2023?",
      "Which gender has shown a higher growth rate in employment in the manufacturing sector for individuals with a high school education?"
    ],
    "Ethinicity Analysis": [
      "Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?",
      "Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?",
      "How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?",
      
    ],
    "Industry Analysis": [
      "What is the trend in employment counts for the Aerospace Product and Parts Manufacturing sector from 2018 to 2023?",
      "Which manufacturing industry sector had the highest number of employees in 2021?"
    ],
    "Overview": [
      "Utah Manufacturing Workforce Overview",
      "Executive Summary"
    ]
  };

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    if (input.trim() === '') {
      setRecommendations([]);
      return;
    }

    const matched = [];
    Object.keys(faqData).forEach((tab) => {
      faqData[tab].forEach((question) => {
        if (question.toLowerCase().includes(input.toLowerCase())) {
          matched.push({ tab, question });
        }
      });
    });
    setRecommendations(matched);
  };

 

  const handleRecommendationClick = (tab, question) => {
    navigate(`/faq?tab=${tab}&question=${encodeURIComponent(question)}`);
  };

  return (
    <>
      <div>
        <Swiper direction="horizontal" loop={true} pagination={{ clickable: true }} className="swiper-container mt-adjustment">
          <SwiperSlide style={{ minHeight: "100vh" }}>
            <div className="slide-content" style={{ minHeight: "100vh" }}>
              <img src={image} alt="Utah Economic Data Slide 1" className="slide-image" style={{ minHeight: "100vh" }} />
              <div className="slide-text">Welcome to UTahData.org</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-content">
              <img src={image1} alt="Utah Data and Analytics Slide 2" className="slide-image" style={{ minHeight: "100vh" }} />
              <div className="slide-text">Welcome to UTahData.org</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-content">
              <img src={image2} alt="Data insights for Utah Data Slide 3" className="slide-image" style={{ minHeight: "100vh" }} />
              <div className="slide-text">Welcome to UTahData.org</div>
            </div>
          </SwiperSlide>
        </Swiper>

        {/* Section: Key Economic Verticals */}
        <div className='bg-[#d2eaff] py-[2rem]'>
          <div className="container mx-auto px-4">
            <div className="section-title text-center p-3 bg-gray-100 rounded-lg shadow-md">
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2 mt-4">
                Explore Utah's Key Economic Verticals
              </h2>
              <p className="text-base text-gray-700 max-w-xl mx-auto leading-relaxed">
                Discover insights and data on the industries driving growth and innovation across Utah's diverse economic verticals.
              </p>

              {/* Search Bar */}
              {/* <form
        className="mt-4 flex justify-center items-center sm:mx-[20%] mx-[10%]"
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          type="text"
          placeholder="Search for industries, data..."
          className="w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          value={searchInput}
          onChange={handleSearchChange}
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white font-bold rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Search
        </button>
      </form> */}
   
      {recommendations.length > 0 && (
          <ul className="bg-white border border-gray-300 rounded-md md:max-w-[40vw] sm:max-w-[42vw] max-w-[49vw] mx-auto mt-2 p-2">
            {recommendations.map(({ tab, question }, index) => (
              <li
                key={index}
                className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleRecommendationClick(tab, question)}
              >
                {question}
              </li>
            ))}
          </ul>
        )}
            </div>

                                     <div className="flex justify-center">
                             <div className="sector-cards grid grid-cols-1 sm:grid-cols-2 gap-5 mt-5">
                                 {/* Aerospace and Defense Card */}
                                 <Link to="/aerospace" className="card-link no-underline">
                                     <div className="card bg-white rounded-lg p-5 shadow-md transition-transform transform flex flex-col justify-center items-center hover:scale-105">
                                         <div className="flex flex-col items-center">
                                             <i className="fas fa-fighter-jet text-3xl md:text-4xl lg:text-5xl mb-4 text-[#f0a500]"></i>
                                             <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold text-center text-[#1b2a49]">Aerospace and Defense</h3>
                                             <p className="text-[#666] text-center text-sm md:text-base">Explore data on Utah's aerospace and defense vertical.</p>
                                         </div>
                                     </div>
                                 </Link>

                                 {/* Manufacturing Card */}
                                 <Link to="/faq" className="card-link no-underline">
                                     <div className="card bg-white rounded-lg p-5 shadow-md transition-transform transform flex flex-col justify-center items-center hover:scale-105">
                                         <div className="flex flex-col items-center">
                                             <i className="fas fa-industry text-[#f0a500] text-3xl md:text-4xl lg:text-5xl mb-4"></i>
                                             <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold text-center text-[#1b2a49]">Manufacturing</h3>
                                             <p className="text-[#666] text-center text-sm md:text-base">Data on Utah's manufacturing vertical and industrial growth.</p>
                                         </div>
                                     </div>
                                 </Link>
                             </div>
                         </div>
            
          </div>
        </div>

        {/* Footer Section */}
        <footer className="bg-[#0d2969] h-[15vh] flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center">
            <a href="https://bodex.io/">
              <img src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png" alt="Bodex LLC Logo" className="h-6 mx-4" />
            </a>
            <a href="https://bodex.io/products/kpi-dash/">
              <img src={kpi} alt="KPI Dashboard Logo" className="h-12 md:h-16 lg:h-24 mx-4" />
            </a>
          </div>
        </footer>
      </div>
    </>
  );
}

