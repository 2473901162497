





import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

const ShareChart3 = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        const educationProfileMenChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ['High School', 'Associate Degree', "Bachelor's Degree", 'Graduate Degree'],
                datasets: [{
                    label: 'Education Profile of Men',
                    data: [50, 20, 20, 10],
                    backgroundColor: ['#36a2eb', '#ff6384', '#4bc0c0', '#ffcd56'],
                    hoverOffset: 4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,  // Allows chart to scale based on container size
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Education Profile of Men in Manufacturing Employment'
                    }
                }
            }
        });

        return () => {
            // Clean up chart instance
            educationProfileMenChart.destroy();
        };
    }, []);

    return (
        <div className="flex justify-center items-center w-full h-[60vh]">  {/* Outer container for centering */}
            <div className="w-[50vw] h-[50vh]">  {/* Reduced size */}
                <canvas ref={canvasRef} id="educationProfileMenChart" className="w-full h-full" />
            </div>
        </div>
    );
};

export default ShareChart3;
