import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

export default function TableNo18() {
    // Data for the table
    const data = {
        categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
        female: [39659, 41893, 42644, 46774, 49196, 49347],
        male: [94477, 96113, 96190, 101827, 104344, 104888],
    };

    // Chart data
    const chartData = {
        labels: data.categories,
        datasets: [
            {
                label: 'Female',
                data: data.female,
                borderColor: 'rgba(255, 99, 132, 0.7)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.3,
            },
            {
                label: 'Male',
                data: data.male,
                borderColor: 'rgba(54, 162, 235, 0.7)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.3,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Employment Trend (2018-2023)',
            },
        },
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    callback: value => `${value.toLocaleString()}`, // Format y-axis values
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Employment Trend (2018-2023)</h2>

            {/* Responsive Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Year</th>
                            {data.categories.map((year, index) => (
                                <th key={index} className="px-4 py-2">{year}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b">
                            <td className="px-4 py-2 font-semibold">Female</td>
                            {data.female.map((value, index) => (
                                <td key={index} className="px-4 py-2">{value.toLocaleString()}</td>
                            ))}
                        </tr>
                        <tr className="border-b">
                            <td className="px-4 py-2 font-semibold">Male</td>
                            {data.male.map((value, index) => (
                                <td key={index} className="px-4 py-2">{value.toLocaleString()}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Responsive Line Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
}
