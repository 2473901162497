
import React, { useRef, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../1.png';
import './AboutUs.css'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import kpi from '../KPI-DASH 2.png';
import { Link } from 'react-router-dom';
import './Verticals.css';
import aerospace from '../aerospace-and-defence.jpeg'
import agriculture from '../agriculture-food-natural-resources.jpg'
import business from '../business-finance-marketing.webp'
import engineering from '../engineering-technology.jpg'
import human from '../human-services.jpeg'
import transport from '../transportation-distribution-logistics.jpg'
import architecture from '../Architectural-Engineering-construction.jpg'
import computer from '../computer-science-it.webp'
import health from '../Heath-Science.jpg'
import law from '../law-public-safety-security.jpg'
import arts from '../arts-visual-communication.jpeg'
import education from '../education-training.webp'
import hospitality from '../hospitality-tourism.jpg'
import manufacturing from '../manufacturing.jpg'

export default function Verticals() {
  const iconData = [
    { icon: 'fa-fighter-jet', title: 'Aerospace and Defense', backgroundImage:  `url(${aerospace})`},
    { icon: 'fa-seedling', title: 'Agriculture, Food, and Natural Resources', backgroundImage: `url(${agriculture})` },
    { icon: 'fa-briefcase', title: 'Business, Finance, and Marketing', backgroundImage: `url(${business})` },
    { icon: 'fa-cogs', title: 'Engineering and Technology', backgroundImage: `url(${engineering})`},
    { icon: 'fa-hands-helping', title: 'Human Services', backgroundImage:`url(${human})` },
    { icon: 'fa-truck', title: 'Transportation, Distribution, and Logistics', backgroundImage: `url(${transport})` },
    { icon: 'fa-building', title: 'Architecture and Construction', backgroundImage: `url(${architecture})` },
    { icon: 'fa-laptop-code', title: 'Computer Science and IT', backgroundImage: `url(${computer})` },
    { icon: 'fa-heartbeat', title: 'Health Science', backgroundImage:`url(${health})` },
    { icon: 'fa-balance-scale', title: 'Law, Public Safety, Corrections, and Security', backgroundImage: `url(${law})` },
    { icon: 'fa-paint-brush', title: 'Arts, Audio/Visual Technology, and Communication', backgroundImage: `url(${arts})` },
    { icon: 'fa-graduation-cap', title: 'Education and Training', backgroundImage: `url(${education})` },
    { icon: 'fa-concierge-bell', title: 'Hospitality and Tourism', backgroundImage: `url(${hospitality})` },
    { icon: 'fa-industry', title: 'Manufacturing', backgroundImage: `url(${manufacturing})` },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse = navbarRef.current.querySelector('.navbar-collapse');
        if (navbarCollapse) {
          navbarCollapse.classList.remove('show'); // Close the navbar
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: '13vh',
          backgroundColor: '#1e59be',
          zIndex: 5,
          transition: 'background-color 0.3s ease',
          position: 'fixed',
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img className="h-[8vh] md:h-[10vh]" src={logo} alt="UTahData Logo" />
              <h1 className="text-white ml-2 text-lg md:text-4xl">UTahData.org
              <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">Beta</span>
              </h1>
            </div>
          </Navbar.Brand>
          
          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav
              className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center"
            >
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

         {/* Main content */}
      <div className="about-us-container bg-[#d2eaff]">
        <h2 className="about-us-title">
          <i className="fas fa-info-circle"></i> Verticals
        </h2>
       
        <div className="icon-container">
          {iconData.map((icon, index) => (
            <div className="icon-box" key={index}
              style={{
                backgroundImage: icon.backgroundImage,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
              <i className={`fas ${icon.icon} `}></i>
              <h3 className='text-white'>{icon.title}</h3>
              <img src={icon.backgroundImage} alt={`Icon representing ${icon.title}`} className="hidden-img" />
            </div>
          ))}
        </div>
        </div>




      <footer className="bg-[#0d2969] h-[15vh] mt-[0rem] flex items-center justify-center">
        <div className="flex items-center">
        


          <a href="https://bodex.io/">
                            <img
                                src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
                                alt="Logo 1"
                                className="h-6 mx-4"
                            />
                        </a>
                        <a href="https://bodex.io/products/kpi-dash/">
                            <img src={kpi} alt="Logo 2" className="h-12 md:h-16 lg:h-24 mx-4" />
                        </a>
        </div>
      </footer>
    </>
  );
}


