// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo33 = () => {
//   // Data for the table and chart
//   const employmentTrendData = [
//     { raceLabel: 'American Indian or Alaska Native Alone', employmentTrend: 377 },
//     { raceLabel: 'Asian Alone', employmentTrend: 1090 },
//     { raceLabel: 'Black or African American Alone', employmentTrend: 1480 },
//     { raceLabel: 'Native Hawaiian or Other Pacific Islander Alone', employmentTrend: 163 },
//     { raceLabel: 'Two or More Race Groups', employmentTrend: 945 },
//     { raceLabel: 'White Alone', employmentTrend: 16063 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: employmentTrendData.map(item => item.raceLabel),
//     datasets: [
//       {
//         label: 'Employment Trend (2023-2018)',
//         data: employmentTrendData.map(item => item.employmentTrend),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => `${value}`, // Display numbers on the Y-axis
//         },
//       },
//     },
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw}`, // Show value in tooltip
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Employment Trend by Race (2023-2018)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Race Label</th>
//             <th>Employment Trend (2023-2018)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {employmentTrendData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.raceLabel}</td>
//               <td>{item.employmentTrend}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div style={{ height: '400px', width: '100%' }}>
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo33;




import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo33 = () => {
  // Data for the table and chart
  const employmentTrendData = [
    { raceLabel: 'American Indian or Alaska Native Alone', employmentTrend: 377 },
    { raceLabel: 'Asian Alone', employmentTrend: 1090 },
    { raceLabel: 'Black or African American Alone', employmentTrend: 1480 },
    { raceLabel: 'Native Hawaiian or Other Pacific Islander Alone', employmentTrend: 163 },
    { raceLabel: 'Two or More Race Groups', employmentTrend: 945 },
    { raceLabel: 'White Alone', employmentTrend: 16063 },
  ];

  // Chart data
  const chartData = {
    labels: employmentTrendData.map(item => item.raceLabel),
    datasets: [
      {
        label: 'Employment Trend (2023-2018)',
        data: employmentTrendData.map(item => item.employmentTrend),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}`, // Display numbers on the Y-axis
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: 'Employment Count',
          color: '#4B5563',
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`, // Show value in tooltip
        },
        bodyFont: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Employment Trend by Race (2023-2018)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive className="mb-4">
        <thead>
          <tr>
            <th>Race Label</th>
            <th>Employment Trend (2023-2018)</th>
          </tr>
        </thead>
        <tbody>
          {employmentTrendData.map((item, index) => (
            <tr key={index}>
              <td>{item.raceLabel}</td>
              <td>{item.employmentTrend}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart with Horizontal Scroll */}
      <div className="overflow-auto">
        <div style={{ minWidth: '600px', height: '400px' }}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo33;
