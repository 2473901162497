// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo37 = () => {
//   // Data for the table and chart
//   const industryData = [
//     { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
//     { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
//     { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
//     { sector: 'Animal Food Manufacturing', employment: 1279 },
//     { sector: 'Animal Slaughtering and Processing', employment: 4116 },
//     { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
//     { sector: 'Apparel Knitting Mills', employment: 41 },
//     { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
//     { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
//     { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
//     { sector: 'Basic Chemical Manufacturing', employment: 1249 },
//     { sector: 'Beverage Manufacturing', employment: 1359 },
//     { sector: 'Boiler, Tank, and Shipping Container Manufacturing', employment: 662 },
//     { sector: 'Cement and Concrete Product Manufacturing', employment: 3012 },
//     { sector: 'Clay Product and Refractory Manufacturing', employment: 196 },
//     { sector: 'Coating, Engraving, Heat Treating, and Allied Activities', employment: 882 },
//     { sector: 'Commercial and Service Industry Machinery Manufacturing', employment: 967 },
//     { sector: 'Communications Equipment Manufacturing', employment: 486 },
//     { sector: 'Computer and Peripheral Equipment Manufacturing', employment: 434 },
//     { sector: 'Converted Paper Product Manufacturing', employment: 2464 },
//     { sector: 'Cut and Sew Apparel Manufacturing', employment: 306 },
//     { sector: 'Cutlery and Handtool Manufacturing', employment: 157 },
//     { sector: 'Dairy Product Manufacturing', employment: 4220 },
//     { sector: 'Electric Lighting Equipment Manufacturing', employment: 87 },
//     { sector: 'Electrical Equipment Manufacturing', employment: 282 },
//     { sector: 'Engine, Turbine, and Power Transmission Equipment Manufacturing', employment: 382 },
//     { sector: 'Fabric Mills', employment: 57 },
//     { sector: 'Footwear Manufacturing', employment: 13 },
//     { sector: 'Forging and Stamping', employment: 147 },
//     { sector: 'Foundries', employment: 887 },
//     { sector: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing', employment: 1878 },
//     { sector: 'Glass and Glass Product Manufacturing', employment: 286 },
//     { sector: 'Grain and Oilseed Milling', employment: 572 },
//     { sector: 'Hardware Manufacturing', employment: 65 },
//     { sector: 'Household and Institutional Furniture and Kitchen Cabinet Manufacturing', employment: 4298 },
//     { sector: 'Household Appliance Manufacturing', employment: 257 },
//     { sector: 'Industrial Machinery Manufacturing', employment: 549 },
//     { sector: 'Iron and Steel Mills and Ferroalloy Manufacturing', employment: 647 },
//     { sector: 'Lime and Gypsum Product Manufacturing', employment: 235 },
//     { sector: 'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing', employment: 2959 },
//     { sector: 'Manufacturing and Reproducing Magnetic and Optical Media', employment: 189 },
//     { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
//     { sector: 'Metalworking Machinery Manufacturing', employment: 710 },
//     { sector: 'Motor Vehicle Body and Trailer Manufacturing', employment: 1738 },
//     { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
//     { sector: 'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing', employment: 9084 },
//     { sector: 'Nonferrous Metal (except Aluminum) Production and Processing', employment: 1587 },
//     { sector: 'Office Furniture (including Fixtures) Manufacturing', employment: 840 },
//     { sector: 'Other Chemical Product and Preparation Manufacturing', employment: 168 },
//     { sector: 'Other Electrical Equipment and Component Manufacturing', employment: 1204 },
//     { sector: 'Other Fabricated Metal Product Manufacturing', employment: 3160 },
//     { sector: 'Other Food Manufacturing', employment: 2685 },
//     { sector: 'Other Furniture Related Product Manufacturing', employment: 2444 },
//     { sector: 'Other General Purpose Machinery Manufacturing', employment: 2529 },
//     { sector: 'Other Leather and Allied Product Manufacturing', employment: 197 },
//     { sector: 'Other Miscellaneous Manufacturing', employment: 9181 },
//     { sector: 'Other Nonmetallic Mineral Product Manufacturing', employment: 1810 },
//     { sector: 'Other Textile Product Mills', employment: 628 },
//     { sector: 'Other Transportation Equipment Manufacturing', employment: 410 },
//     { sector: 'Other Wood Product Manufacturing', employment: 1959 },
//     { sector: 'Paint, Coating, and Adhesive Manufacturing', employment: 198 },
//     { sector: 'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing', employment: 67 },
//     { sector: 'Petroleum and Coal Products Manufacturing', employment: 1806 },
//     { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
//     { sector: 'Plastics Product Manufacturing', employment: 7519 },
//     { sector: 'Printing and Related Support Activities', employment: 5120 },
//     { sector: 'Railroad Rolling Stock Manufacturing', employment: 378 },
//     { sector: 'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing', employment: 235 },
//     { sector: 'Rubber Product Manufacturing', employment: 625 },
//     { sector: 'Sawmills and Wood Preservation', employment: 153 },
//     { sector: 'Seafood Product Preparation and Packaging', employment: 8 },
//     { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
//     { sector: 'Ship and Boat Building', employment: 122 },
//     { sector: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing', employment: 1032 },
//     { sector: 'Spring and Wire Product Manufacturing', employment: 115 },
//     { sector: 'Steel Product Manufacturing from Purchased Steel', employment: 184 },
//     { sector: 'Sugar and Confectionery Product Manufacturing', employment: 1006 },
//     { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
//     { sector: 'Textile Furnishings Mills', employment: 228 },
//     { sector: 'Veneer, Plywood, and Engineered Wood Product Manufacturing', employment: 925 },
//     { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 }
//   ];

//   // Chart data
//   const chartData = {
//     labels: industryData.map(item => item.sector),
//     datasets: [
//       {
//         label: 'Employment Count',
//         data: industryData.map(item => item.employment),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Employment by Industry Sector in 2021</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Industry Sector</th>
//             <th>Employment Count</th>
//           </tr>
//         </thead>
//         <tbody>
//           {industryData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.sector}</td>
//               <td>{item.employment.toLocaleString()}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div style={{ height: '500px', width: '100%' }}>
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo37;








import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo37 = () => {
  // Data for the table and chart
  const industryData = [
    { sector: 'Aerospace Product and Parts Manufacturing', employment: 10564 },
    { sector: 'Agriculture, Construction, and Mining Machinery Manufacturing', employment: 859 },
    { sector: 'Alumina and Aluminum Production and Processing', employment: 584 },
    { sector: 'Animal Food Manufacturing', employment: 1279 },
    { sector: 'Animal Slaughtering and Processing', employment: 4116 },
    { sector: 'Apparel Accessories and Other Apparel Manufacturing', employment: 157 },
    { sector: 'Apparel Knitting Mills', employment: 41 },
    { sector: 'Architectural and Structural Metals Manufacturing', employment: 7098 },
    { sector: 'Audio and Video Equipment Manufacturing', employment: 276 },
    { sector: 'Bakeries and Tortilla Manufacturing', employment: 4002 },
    { sector: 'Basic Chemical Manufacturing', employment: 1249 },
    { sector: 'Beverage Manufacturing', employment: 1359 },
    { sector: 'Medical Equipment and Supplies Manufacturing', employment: 12902 },
    { sector: 'Motor Vehicle Parts Manufacturing', employment: 4380 },
    { sector: 'Pharmaceutical and Medicine Manufacturing', employment: 8005 },
    { sector: 'Plastics Product Manufacturing', employment: 7519 },
    { sector: 'Printing and Related Support Activities', employment: 5120 },
    { sector: 'Semiconductor and Other Electronic Component Manufacturing', employment: 3173 },
    { sector: 'Textile and Fabric Finishing and Fabric Coating Mills', employment: 349 },
    { sector: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing', employment: 573 },
  ];

  // Chart data
  const chartData = {
    labels: industryData.map(item => item.sector),
    datasets: [
      {
        label: 'Employment Count',
        data: industryData.map(item => item.employment),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: 'Employment Count',
          color: '#4B5563',
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
          autoSkip: false, // Ensure all labels are shown
          maxRotation: 90,
          minRotation: 45,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`, // Add commas to large numbers
        },
        bodyFont: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Employment by Industry Sector in 2021</h2>

      {/* Responsive Table with Horizontal Scroll */}
      <div className="table-responsive mb-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Industry Sector</th>
              <th>Employment Count</th>
            </tr>
          </thead>
          <tbody>
            {industryData.map((item, index) => (
              <tr key={index}>
                <td>{item.sector}</td>
                <td>{item.employment.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Bar Chart with Horizontal Scroll */}
      <div className="overflow-auto">
        <div style={{ minWidth: '800px', height: '500px' }}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo37;
