import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo29 = () => {
  // Data for the table and chart
  const data = [
    { ageGroup: '14-18', allPercentage: '1.63%' },
    { ageGroup: '19-21', allPercentage: '4.24%' },
    { ageGroup: '22-24', allPercentage: '6.03%' },
    { ageGroup: '25-34', allPercentage: '22.17%' },
    { ageGroup: '35-44', allPercentage: '22.80%' },
    { ageGroup: '45-54', allPercentage: '20.86%' },
    { ageGroup: '55-64', allPercentage: '16.23%' },
    { ageGroup: '65-99', allPercentage: '6.04%' },
  ];

  // Chart data
  const chartData = {
    labels: data.map(item => item.ageGroup),
    datasets: [
      {
        label: 'All %',
        data: data.map(item => parseFloat(item.allPercentage)),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`,  // Show percentage symbol on Y-axis
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`,  // Show percentage in tooltip
        },
      },
    },
  };

  return (
    <div className="container">
      <h2>All Percentage by Age Group</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Age Group</th>
            <th>All %</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.ageGroup}</td>
              <td>{item.allPercentage}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TableNo29;
