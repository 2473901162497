import AboutUs from "./components/AboutUs";
import Faq from "./components/Faq";
import Navbar1 from "./components/Navbar";
import HomePage from "./components/HomePage";
import { HashRouter as Router , Routes , Route } from 'react-router-dom'
import ContactUs from "./components/ContactUs";
import { useRef } from "react";
import Verticals from "./components/Verticals";
import Aerospace from "./components/Aerospace";



function App() {

  const swiperRef = useRef(null);
  return (
    <div>
    <Router>
      <Navbar1 swiperRef={swiperRef}/>
      <Routes>
      <Route path="/verticals" element={<Verticals/>} />
      <Route path="/about" element={<AboutUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact-us" element={<ContactUs/>}/>
          <Route path="/" element={<HomePage swiperRef={swiperRef}/>} /> 
          <Route path="/aerospace" element={<Aerospace/>}/>
      </Routes>
  
      </Router>
     
      
    </div>
  );
}

export default App;
