import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo23() {
    // Data for the table and chart
    const data = {
        year: '2023',
        maleEmployees: 104888,
    };

    // Chart data configuration
    const chartData = {
        labels: [data.year],
        datasets: [
            {
                label: 'Total No: of Male Employees in 2023',
                data: [data.maleEmployees],
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Number of Male Employees in 2023',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: value => value.toLocaleString(), // Format y-axis values with commas
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Number of Male Employees in 2023</h2>

            {/* Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Year</th>
                            <th className="px-4 py-2">Total No: of Male Employees</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b">
                            <td className="px-4 py-2 font-semibold">{data.year}</td>
                            <td className="px-4 py-2">{data.maleEmployees.toLocaleString()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
