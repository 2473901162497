




import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

const ShareChart4 = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        const educationComparisonChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['High School', 'Associate Degree', "Bachelor's Degree", 'Graduate Degree'],
                datasets: [
                    {
                        label: 'Women',
                        data: [40, 25, 25, 10],
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Men',
                        data: [50, 20, 20, 10],
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,  // Allows chart to scale properly
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Percentage (%)'
                        }
                    }
                }
            }
        });

        return () => {
            // Clean up chart instance
            educationComparisonChart.destroy();
        };
    }, []);

    return (
        <div className="flex justify-center items-center w-full h-[60vh]">  {/* Centering the chart */}
            <div className="w-[50vw] h-[50vh]">  {/* Adjusted size */}
                <canvas ref={canvasRef} id="educationComparisonChart" className="w-full h-full" />
            </div>
        </div>
    );
};

export default ShareChart4;
