// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo12 = () => {
//   // Data for the table and chart
//   const educationData = [
//     { level: 'Less than high school', share2018: 16.36, share2023: 16.71 },
//     { level: 'High school or equivalent, no college', share2018: 24.44, share2023: 22.91 },
//     { level: 'Some college or Associate degree', share2018: 27.79, share2023: 26.81 },
//     { level: "Bachelor's degree or advanced degree", share2018: 18.94, share2023: 19.76 },
//     { level: 'Educational attainment not available (workers < 24)', share2018: 12.46, share2023: 13.81 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: educationData.map(item => item.level),
//     datasets: [
//       {
//         label: 'Female Share % (2018)',
//         data: educationData.map(item => item.share2018),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Female Share % (2023)',
//         data: educationData.map(item => item.share2023),
//         backgroundColor: 'rgba(255, 99, 132, 0.6)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Bar chart options
//   const options = {
//     responsive: true,
//     scales: {
//       y: {
//         beginAtZero: true,
//         max: 30, // Adjusted to fit the data range
//         ticks: {
//           callback: (value) => value + '%', // Append % sign to ticks
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Female Share Comparison by Education Level (2018 vs 2023)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Education Level</th>
//             <th>Female Share % (2018)</th>
//             <th>Female Share % (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {educationData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.level}</td>
//               <td>{item.share2018.toFixed(2)}%</td>
//               <td>{item.share2023.toFixed(2)}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div style={{ height: '400px', width: '100%' }}>
//         <Bar data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo12
// ;












import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo12 = () => {
  // Data for the table and chart
  const educationData = [
    { level: 'Less than high school', share2018: 16.36, share2023: 16.71 },
    { level: 'High school or equivalent, no college', share2018: 24.44, share2023: 22.91 },
    { level: 'Some college or Associate degree', share2018: 27.79, share2023: 26.81 },
    { level: "Bachelor's degree or advanced degree", share2018: 18.94, share2023: 19.76 },
    { level: 'Educational attainment not available (workers < 24)', share2018: 12.46, share2023: 13.81 },
  ];

  // Chart data
  const chartData = {
    labels: educationData.map(item => item.level),
    datasets: [
      {
        label: 'Female Share % (2018)',
        data: educationData.map(item => item.share2018),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Female Share % (2023)',
        data: educationData.map(item => item.share2023),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Bar chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 30,
        ticks: {
          callback: (value) => value + '%', // Append % sign to ticks
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: 'Percentage Share',
          color: '#4B5563',
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: '#4B5563',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show value in tooltip with %
        },
        bodyFont: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Female Share Comparison by Education Level (2018 vs 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive className="mb-4">
        <thead>
          <tr>
            <th>Education Level</th>
            <th>Female Share % (2018)</th>
            <th>Female Share % (2023)</th>
          </tr>
        </thead>
        <tbody>
          {educationData.map((item, index) => (
            <tr key={index}>
              <td>{item.level}</td>
              <td>{item.share2018.toFixed(2)}%</td>
              <td>{item.share2023.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart with Horizontal Scroll */}
      <div className="overflow-auto">
        <div style={{ minWidth: '600px', height: '400px' }}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo12;
