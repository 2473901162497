import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo35 = () => {
  // Data for the table and chart
  const employmentData = [
    { ethnicityLabel: 'All Ethnicities', emp2018: 134044, emp2022: 153477, emp2023: 154153 },
    { ethnicityLabel: 'Hispanic or Latino', emp2018: 25385, emp2022: 30655, emp2023: 31600 },
    { ethnicityLabel: 'Not Hispanic or Latino', emp2018: 108486, emp2022: 122637, emp2023: 122389 },
  ];

  // Chart data
  const chartData = {
    labels: employmentData.map(item => item.ethnicityLabel),
    datasets: [
      {
        label: 'Emp. Count (2018)',
        data: employmentData.map(item => item.emp2018),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Emp. Count (2022)',
        data: employmentData.map(item => item.emp2022),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Emp. Count (2023)',
        data: employmentData.map(item => item.emp2023),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(), // Format Y-axis with commas
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`, // Show commas in tooltip values
        },
      },
    },
  };

  return (
    <div className="container">
      <h2>Employment Count by Ethnicity (2018, 2022, 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Ethnicity Label</th>
            <th>Emp. Count (2018)</th>
            <th>Emp. Count (2022)</th>
            <th>Emp. Count (2023)</th>
          </tr>
        </thead>
        <tbody>
          {employmentData.map((item, index) => (
            <tr key={index}>
              <td>{item.ethnicityLabel}</td>
              <td>{item.emp2018.toLocaleString()}</td>
              <td>{item.emp2022.toLocaleString()}</td>
              <td>{item.emp2023.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TableNo35;
