import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo36 = () => {
  // Data for the table and chart
  const aerospaceData = [
    { year: 2018, employment: 8103 },
    { year: 2019, employment: 8496 },
    { year: 2020, employment: 8785 },
    { year: 2021, employment: 10564 },
    { year: 2022, employment: 11667 },
    { year: 2023, employment: 13321 },
  ];

  // Chart data
  const chartData = {
    labels: aerospaceData.map(item => item.year.toString()),
    datasets: [
      {
        label: 'Employment Count',
        data: aerospaceData.map(item => item.employment),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(), // Format Y-axis with commas
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`, // Show commas in tooltip values
        },
      },
    },
  };

  return (
    <div className="container">
      <h2>Employment in Aerospace Product and Parts Manufacturing (2018 - 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Year</th>
            <th>Employment Count</th>
          </tr>
        </thead>
        <tbody>
          {aerospaceData.map((item, index) => (
            <tr key={index}>
              <td>{item.year}</td>
              <td>{item.employment.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TableNo36;
