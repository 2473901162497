import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo32 = () => {
  // Data for the table and chart
  const educationGenderShareData = [
    {
      educationLevel: 'High school or equivalent, no college',
      femaleShare2018: 24.44,
      maleShare2018: 25.32,
      femaleShare2023: 22.91,
      maleShare2023: 24.67,
    },
  ];

  // Chart data
  const chartData = {
    labels: educationGenderShareData.map(item => item.educationLevel),
    datasets: [
      {
        label: 'Female Share % (2018)',
        data: educationGenderShareData.map(item => item.femaleShare2018),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Male Share % (2018)',
        data: educationGenderShareData.map(item => item.maleShare2018),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Female Share % (2023)',
        data: educationGenderShareData.map(item => item.femaleShare2023),
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
      {
        label: 'Male Share % (2023)',
        data: educationGenderShareData.map(item => item.maleShare2023),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`, // Display percentage symbol on the Y-axis
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show percentage in tooltip
        },
      },
    },
  };

  return (
    <div className="container">
      <h2>Education Level Share by Gender (2018 vs 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Education Level</th>
            <th>Female Share % (2018)</th>
            <th>Male Share % (2018)</th>
            <th>Female Share % (2023)</th>
            <th>Male Share % (2023)</th>
          </tr>
        </thead>
        <tbody>
          {educationGenderShareData.map((item, index) => (
            <tr key={index}>
              <td>{item.educationLevel}</td>
              <td>{item.femaleShare2018}%</td>
              <td>{item.maleShare2018}%</td>
              <td>{item.femaleShare2023}%</td>
              <td>{item.maleShare2023}%</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TableNo32;
