import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo10() {
    // Age Group Data
    const ageGroupData = [
        { ageGroup: '14-18', femaleShare: 2.43, maleShare: 1.26 },
        { ageGroup: '19-21', femaleShare: 4.88, maleShare: 3.94 },
        { ageGroup: '22-24', femaleShare: 6.42, maleShare: 5.84 },
        { ageGroup: '25-34', femaleShare: 20.93, maleShare: 22.75 },
        { ageGroup: '35-44', femaleShare: 21.56, maleShare: 23.38 },
        { ageGroup: '45-54', femaleShare: 21.50, maleShare: 20.56 },
        { ageGroup: '55-64', femaleShare: 16.02, maleShare: 16.32 },
        { ageGroup: '65-99', femaleShare: 6.24, maleShare: 5.94 },
    ];

    // Chart data configuration
    const chartData = {
        labels: ageGroupData.map((data) => data.ageGroup),
        datasets: [
            {
                label: 'Female Share %',
                data: ageGroupData.map((data) => data.femaleShare),
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Male Share %',
                data: ageGroupData.map((data) => data.maleShare),
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Female vs. Male Share % by Age Group',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => `${value}%`, // Add % symbol to y-axis labels
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Age Group Share % by Gender</h2>

            {/* Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Age Group Label</th>
                            <th className="px-4 py-2">Female Share %</th>
                            <th className="px-4 py-2">Male Share %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ageGroupData.map((row, index) => (
                            <tr key={index} className="border-b">
                                <td className="px-4 py-2 font-semibold">{row.ageGroup}</td>
                                <td className="px-4 py-2">{row.femaleShare}%</td>
                                <td className="px-4 py-2">{row.maleShare}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Grouped Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
