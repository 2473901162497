



import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo8() {
    // Data for the table and chart
    const data = [
        { year: 2018, female: 39659, male: 94477 },
        { year: 2019, female: 41893, male: 96113 },
        { year: 2020, female: 42644, male: 96190 },
        { year: 2021, female: 46774, male: 101827 },
        { year: 2022, female: 49196, male: 104344 },
        { year: 2023, female: 49347, male: 104888 },
    ];

    // Chart data
    const chartData = {
        labels: data.map(item => item.year),
        datasets: [
            {
                label: 'Female',
                data: data.map(item => item.female),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Male',
                data: data.map(item => item.male),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Employment Trend (2018-2023)',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Employment Data (2018-2023)</h2>

            {/* Responsive Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Year</th>
                            <th className="px-4 py-2">Female</th>
                            <th className="px-4 py-2">Male</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => (
                            <tr key={row.year} className="border-b">
                                <td className="px-4 py-2">{row.year}</td>
                                <td className="px-4 py-2">{row.female.toLocaleString()}</td>
                                <td className="px-4 py-2">{row.male.toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Responsive Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
