// ManufacturingCategoryAnalysis.js
import React from 'react';

const Table5 = () => {
    return (
        <div className="faq-item">
            
            <div className="faq-answer">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Manufacturing Category</th>
                            <th>Q4-2018</th>
                            <th>Q4-2019</th>
                            <th>Q4-2020</th>
                            <th>Q4-2021</th>
                            <th>Q4-2022</th>
                            <th>Q4-2023</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Food Manufacturing</td>
                            <td>24,000</td>
                            <td>25,000</td>
                            <td>25,500</td>
                            <td>26,000</td>
                            <td>27,000</td>
                            <td>28,000</td>
                        </tr>
                        <tr>
                            <td>Textile Manufacturing</td>
                            <td>18,000</td>
                            <td>17,500</td>
                            <td>17,000</td>
                            <td>16,500</td>
                            <td>16,000</td>
                            <td>15,500</td>
                        </tr>
                        {/* Additional rows can be added here */}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    );
};

export default Table5;
