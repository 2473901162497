






import React, { useRef, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../1.png';
import './AboutUs.css'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import kpi from '../KPI-DASH 2.png';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  const iconData = [
    { icon: 'fa-fighter-jet', title: 'Aerospace and Defense' },
    { icon: 'fa-seedling', title: 'Agriculture, Food, and Natural Resources' },
    { icon: 'fa-briefcase', title: 'Business, Finance, and Marketing' },
    { icon: 'fa-cogs', title: 'Engineering and Technology' },
    { icon: 'fa-hands-helping', title: 'Human Services' },
    { icon: 'fa-truck', title: 'Transportation, Distribution, and Logistics' },
    { icon: 'fa-building', title: 'Architecture and Construction' },
    { icon: 'fa-laptop-code', title: 'Computer Science and IT' },
    { icon: 'fa-heartbeat', title: 'Health Science' },
    { icon: 'fa-balance-scale', title: 'Law, Public Safety, Corrections, and Security' },
    { icon: 'fa-paint-brush', title: 'Arts, Audio/Visual Technology, and Communication' },
    { icon: 'fa-graduation-cap', title: 'Education and Training' },
    { icon: 'fa-concierge-bell', title: 'Hospitality and Tourism' },
    { icon: 'fa-industry', title: 'Manufacturing' },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse = navbarRef.current.querySelector('.navbar-collapse');
        if (navbarCollapse) {
          navbarCollapse.classList.remove('show'); // Close the navbar
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col min-h-screen">
      <Navbar
        ref={navbarRef} // Add ref here
        collapseOnSelect
        expand="lg"
        className="fixed top-0 left-0 w-full z-10 flex justify-center items-center"
        style={{
          height: '13vh',
          backgroundColor: '#1e59be',
          zIndex: 5,
          transition: 'background-color 0.3s ease',
          position: 'fixed',
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="flex items-center">
              <img className="h-[8vh] md:h-[10vh]" src={logo} alt="UTahData Logo" />
              <h1 className="text-white ml-2 text-lg md:text-4xl">UTahData.org
              <span className="beta-label text-xs bg-yellow-500 text-white px-1 py-0.5 rounded-lg">Beta</span>
              </h1>
            </div>
          </Navbar.Brand>
          
          {/* Toggle icon for responsive menu */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Boxed styling for mobile */}
            <Nav
              className="d-lg-none bg-white border rounded-lg p-4 shadow-lg flex flex-col items-center"
            >
              <Nav.Link
                as={Link}
                to="/"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/faq"
                className="mb-2 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
            </Nav>

            {/* Inline styling for larger screens */}
            <Nav className="d-none d-lg-flex ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Home
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/verticals"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Verticals
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/faq"
                className="mr-4 !text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                FAQs
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="!text-[#FFAA1D] !font-bold hover:bg-[#FFAA1D] hover:!text-black px-4 py-2 rounded transition-colors duration-300 ease-in-out"
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main content */}
      <div className="about-us-container bg-[#d2eaff] flex-grow">
        <h2 className="about-us-title">
          <i className="fas fa-info-circle"></i> About Us
        </h2>
        <p className="about-us-content">
          Our objective is to create an interactive, user-friendly platform that provides comprehensive and up-to-date reporting on Utah’s diverse sectors, including agriculture, business, engineering, technology, health, and education. The Web App will visualize data from Census and other reliable sources in an accessible way, enabling users to explore, analyze, and gain insights into key statistics related to Utah’s demographics, industries, and economic trends.
        </p>
        <p className="about-us-content">
          Through this platform, we aim to enhance awareness, promote informed decision-making, and support the growth and development of Utah’s critical verticals.
        </p>
      </div>

      {/* Footer */}
      <footer className="bg-[#0d2969] h-[15vh] mt-[-7rem] flex items-center justify-center">
        <div className="flex items-center">
        <a href="https://bodex.io/">
                            <img
                                src="https://bodex.io/wp-content/uploads/2020/11/BODEX-LLC-Logo_00fa00371_3580.png"
                                alt="Logo 1"
                                className="h-6 mx-4"
                            />
                        </a>
                        <a href="https://bodex.io/products/kpi-dash/">
                            <img src={kpi} alt="Logo 2" className="h-12 md:h-16 lg:h-24 mx-4" />
                        </a>
        </div>
      </footer>
    </div>
  );
}
