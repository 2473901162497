import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo21() {
    // Age Group Data for Employment in 2018 and 2023
    const ageGroupData = [
        { ageGroup: '14-18', employment2018: 1899, employment2023: 2519 },
        { ageGroup: '19-21', employment2018: 5517, employment2023: 6536 },
        { ageGroup: '22-24', employment2018: 7464, employment2023: 9286 },
        { ageGroup: '25-34', employment2018: 29586, employment2023: 34157 },
        { ageGroup: '35-44', employment2018: 32122, employment2023: 35134 },
        { ageGroup: '45-54', employment2018: 27949, employment2023: 32147 },
        { ageGroup: '55-64', employment2018: 23477, employment2023: 25004 },
        { ageGroup: '65-99', employment2018: 5966, employment2023: 9308 },
    ];

    // Chart data configuration
    const chartData = {
        labels: ageGroupData.map((data) => data.ageGroup),
        datasets: [
            {
                label: '2018',
                data: ageGroupData.map((data) => data.employment2018),
                backgroundColor: 'rgba(75, 192, 192, 0.7)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: '2023',
                data: ageGroupData.map((data) => data.employment2023),
                backgroundColor: 'rgba(255, 159, 64, 0.7)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Employment by Age Group (2018 vs 2023)',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Employment by Age Group for 2018 and 2023</h2>

            {/* Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Age Group Label</th>
                            <th className="px-4 py-2">2018</th>
                            <th className="px-4 py-2">2023</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ageGroupData.map((row, index) => (
                            <tr key={index} className="border-b">
                                <td className="px-4 py-2 font-semibold">{row.ageGroup}</td>
                                <td className="px-4 py-2">{row.employment2018}</td>
                                <td className="px-4 py-2">{row.employment2023}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Grouped Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
