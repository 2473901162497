



import React, { useEffect } from 'react';
import { Chart } from 'chart.js';

const ShareChart2 = () => {
    useEffect(() => {
        const educationProfileWomenCtx = document.getElementById('educationProfileWomenChart').getContext('2d');
        
        const educationProfileWomenChart = new Chart(educationProfileWomenCtx, {
            type: 'doughnut',
            data: {
                labels: ['High School', 'Associate Degree', "Bachelor's Degree", 'Graduate Degree'],
                datasets: [{
                    label: 'Education Profile of Women',
                    data: [40, 25, 25, 10],
                    backgroundColor: ['#ff6384', '#36a2eb', '#ffcd56', '#4bc0c0'],
                    hoverOffset: 4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,  // Allows the chart to adjust based on container size
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Education Profile of Women in Manufacturing Employment'
                    }
                }
            }
        });

        // Cleanup function to destroy the chart when the component unmounts
        return () => {
            educationProfileWomenChart.destroy();
        };
    }, []);

    return (
        <div className="flex justify-center items-center w-full h-[60vh]">  {/* Outer container to center the chart */}
            <div className="w-[50vw] h-[50vh]">  {/* Reduced width and height */}
                <canvas id="educationProfileWomenChart" className="w-full h-full"></canvas>
            </div>
        </div>
    );
};

export default ShareChart2;
