


import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

 export const TableNo25 = () => {
  const ageData = [
    { ageGroup: '14-18', maleCount: 1323 },
    { ageGroup: '19-21', maleCount: 4130 },
    { ageGroup: '22-24', maleCount: 6122 },
    { ageGroup: '25-34', maleCount: 23846 },
    { ageGroup: '35-44', maleCount: 24512 },
    { ageGroup: '45-54', maleCount: 21554 },
    { ageGroup: '55-64', maleCount: 17110 },
    { ageGroup: '65-99', maleCount: 6232 },
  ];

  const chartData = {
    labels: ageData.map(item => item.ageGroup),
    datasets: [
      {
        label: 'Male Count',
        data: ageData.map(item => item.maleCount),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container">
      <h2>Age Group Analysis</h2>
      
      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Age Group</th>
            <th>Male Count</th>
          </tr>
        </thead>
        <tbody>
          {ageData.map((item, index) => (
            <tr key={index}>
              <td>{item.ageGroup}</td>
              <td>{item.maleCount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};


