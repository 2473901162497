import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo27 = () => {
  // Data for the table and chart
  const data = [
    { ageGroup: '14-18', femaleShare2018: '2.00%', femaleShare2023: '2.43%', diffFemale: '0.43%', maleShare2018: '1.17%', maleShare2023: '1.26%', diffMale: '0.09%' },
    { ageGroup: '19-21', femaleShare2018: '4.53%', femaleShare2023: '4.88%', diffFemale: '0.35%', maleShare2018: '3.94%', maleShare2023: '3.94%', diffMale: '0.00%' },
    { ageGroup: '22-24', femaleShare2018: '5.86%', femaleShare2023: '6.42%', diffFemale: '0.56%', maleShare2018: '5.45%', maleShare2023: '5.84%', diffMale: '0.39%' },
    { ageGroup: '25-34', femaleShare2018: '20.48%', femaleShare2023: '20.93%', diffFemale: '0.46%', maleShare2018: '22.76%', maleShare2023: '22.75%', diffMale: '-0.01%' },
    { ageGroup: '35-44', femaleShare2018: '23.13%', femaleShare2023: '21.56%', diffFemale: '-1.57%', maleShare2018: '24.33%', maleShare2023: '23.38%', diffMale: '-0.95%' },
    { ageGroup: '45-54', femaleShare2018: '22.15%', femaleShare2023: '21.50%', diffFemale: '-0.65%', maleShare2018: '20.32%', maleShare2023: '20.56%', diffMale: '0.24%' },
    { ageGroup: '55-64', femaleShare2018: '17.08%', femaleShare2023: '16.02%', diffFemale: '-1.05%', maleShare2018: '17.71%', maleShare2023: '16.32%', diffMale: '-1.39%' },
    { ageGroup: '65-99', femaleShare2018: '4.76%', femaleShare2023: '6.24%', diffFemale: '1.49%', maleShare2018: '4.33%', maleShare2023: '5.94%', diffMale: '1.62%' },
  ];

  // Chart data
  const chartData = {
    labels: data.map(item => item.ageGroup),
    datasets: [
      {
        label: 'Female Share Difference',
        data: data.map(item => parseFloat(item.diffFemale)),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Male Share Difference',
        data: data.map(item => parseFloat(item.diffMale)),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  };

  return (
    <div className="container">
      <h2>Gender Share Analysis (2018 vs 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Age Group</th>
            <th>Female Share % (2018)</th>
            <th>Female Share % (2023)</th>
            <th>Difference (Female)</th>
            <th>Male Share % (2018)</th>
            <th>Male Share % (2023)</th>
            <th>Difference (Male)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.ageGroup}</td>
              <td>{item.femaleShare2018}</td>
              <td>{item.femaleShare2023}</td>
              <td>{item.diffFemale}</td>
              <td>{item.maleShare2018}</td>
              <td>{item.maleShare2023}</td>
              <td>{item.diffMale}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TableNo27;
