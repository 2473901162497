import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo24() {
    // Data for Male Count in 2023 by Age Group
    const ageGroupData = [
        { ageGroup: '14-18', maleCount2023: 1323 },
        { ageGroup: '19-21', maleCount2023: 4130 },
        { ageGroup: '22-24', maleCount2023: 6122 },
        { ageGroup: '25-34', maleCount2023: 23846 },
        { ageGroup: '35-44', maleCount2023: 24512 },
        { ageGroup: '45-54', maleCount2023: 21554 },
        { ageGroup: '55-64', maleCount2023: 17110 },
        { ageGroup: '65-99', maleCount2023: 6232 },
    ];

    // Chart data configuration
    const chartData = {
        labels: ageGroupData.map((data) => data.ageGroup),
        datasets: [
            {
                label: 'Male Count (2023)',
                data: ageGroupData.map((data) => data.maleCount2023),
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Male Count by Age Group (2023)',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Male Count by Age Group (2023)</h2>

            {/* Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Age Group Label</th>
                            <th className="px-4 py-2">Male Count (2023)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ageGroupData.map((row, index) => (
                            <tr key={index} className="border-b">
                                <td className="px-4 py-2 font-semibold">{row.ageGroup}</td>
                                <td className="px-4 py-2">{row.maleCount2023}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
