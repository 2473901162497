




import React from 'react';

const Table1 = () => {
    return (
        <div className="faq-item">
            {/* <div className="faq-question">
                a. Trends in Number of Workers
            </div> */}
            <div className="faq-answer">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Q4-2018</th>
                                <th>Q4-2019</th>
                                <th>Q4-2020</th>
                                <th>Q4-2021</th>
                                <th>Q4-2022</th>
                                <th>Q4-2023</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Count</td>
                                <td>134,137</td>
                                <td>138,003</td>
                                <td>138,837</td>
                                <td>148,597</td>
                                <td>153,540</td>
                                <td>154,235</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table1;
