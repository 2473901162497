



// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

// // Register required components
// ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

// export default function TableNo14() {
//     // Data for the table and chart
//     const employmentData = [
//         { label: 'American Indian or Alaska Native Alone', value: 377 },
//         { label: 'Asian Alone', value: 1090 },
//         { label: 'Black or African American Alone', value: 1480 },
//         { label: 'Native Hawaiian or Other Pacific Islander Alone', value: 163 },
//         { label: 'Two or More Race Groups', value: 945 },
//         { label: 'White Alone', value: 16063 }
//     ];

//     // Line chart data
//     const chartData = {
//         labels: employmentData.map(data => data.label),
//         datasets: [
//             {
//                 label: 'Employment Trend (2023-2018)',
//                 data: employmentData.map(data => data.value),
//                 borderColor: 'rgba(54, 162, 235, 1)',
//                 backgroundColor: 'rgba(54, 162, 235, 0.2)',
//                 pointBackgroundColor: 'rgba(54, 162, 235, 1)',
//                 pointBorderColor: 'rgba(54, 162, 235, 1)',
//                 pointRadius: 5,
//                 fill: true,
//                 tension: 0.3, // Adds curve to the line
//             }
//         ]
//     };

//     const chartOptions = {
//         responsive: true,
//         maintainAspectRatio: false, // Allows the chart to resize properly
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: true,
//                 text: 'Employment Trend by Race (2023-2018)',
//             },
//         },
//         scales: {
//             y: {
//                 beginAtZero: true,
//                 title: {
//                     display: true,
//                     text: 'Employment Count'
//                 }
//             },
//             x: {
//                 title: {
//                     display: true,
//                     text: 'Race Label'
//                 }
//             }
//         }
//     };

//     return (
//         <div className="employment-trend-container">
//             {/* Table */}
//             <table className="table-auto border-collapse w-full my-6 text-left">
//                 <thead>
//                     <tr>
//                         <th className="border px-4 py-2">Race Label</th>
//                         <th className="border px-4 py-2">Employment Trend (2023-2018)</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {employmentData.map((item, index) => (
//                         <tr key={index}>
//                             <td className="border px-4 py-2">{item.label}</td>
//                             <td className="border px-4 py-2">{item.value}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>

//             {/* Responsive Chart Container */}
//             <div className="chart-container w-full md:w-3/4 lg:w-1/2 mx-auto my-6" style={{ position: 'relative', height: '60vh', width: '100%' }}>
//                 <Line data={chartData} options={chartOptions} />
//             </div>
//         </div>
//     );
// }








import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

// Register required components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

export default function TableNo14() {
  // Data for the table and chart
  const employmentData = [
    { label: 'American Indian or Alaska Native Alone', value: 377 },
    { label: 'Asian Alone', value: 1090 },
    { label: 'Black or African American Alone', value: 1480 },
    { label: 'Native Hawaiian or Other Pacific Islander Alone', value: 163 },
    { label: 'Two or More Race Groups', value: 945 },
    { label: 'White Alone', value: 16063 },
  ];

  // Line chart data
  const chartData = {
    labels: employmentData.map((data) => data.label),
    datasets: [
      {
        label: 'Employment Trend (2023-2018)',
        data: employmentData.map((data) => data.value),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        pointBorderColor: 'rgba(54, 162, 235, 1)',
        pointRadius: 5,
        fill: true,
        tension: 0.3, // Adds curve to the line
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to resize properly
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Employment Trend by Race (2023-2018)',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Employment Count',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Race Label',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };

  return (
    <div className="employment-trend-container">
      {/* Table */}
      <table className="table-auto border-collapse w-full my-6 text-left">
        <thead>
          <tr>
            <th className="border px-4 py-2">Race Label</th>
            <th className="border px-4 py-2">Employment Trend (2023-2018)</th>
          </tr>
        </thead>
        <tbody>
          {employmentData.map((item, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{item.label}</td>
              <td className="border px-4 py-2">{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Responsive Chart Container */}
      <div className="overflow-x-auto">
        <div
          className="chart-container w-full md:w-3/4 lg:w-1/2 mx-auto my-6"
          style={{
            position: 'relative',
            height: '60vh',
            minWidth: '500px', // Ensure minimum width for better view on mobile
          }}
        >
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}
