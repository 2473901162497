

import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

const ShareChart5 = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        const ageProfileChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['14-18', '19-21', '22-24', '25-34', '35-44', '45-54', '55-64', '65+'],
                datasets: [
                    {
                        label: 'Women',
                        data: [5, 10, 15, 20, 25, 20, 15, 10],
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        fill: true
                    },
                    {
                        label: 'Men',
                        data: [8, 12, 18, 22, 28, 22, 18, 12],
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        fill: true
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Count'
                        }
                    }
                }
            }
        });

        return () => {
            // Clean up chart instance
            ageProfileChart.destroy();
        };
    }, []);

    return (
        <div className="flex justify-center items-center w-full h-[60vh]"> {/* Centering the chart */}
            <div className="w-[50vw] h-[50vh]"> {/* Adjusted size */}
                <canvas ref={canvasRef} id="ageProfileChart" className="w-full h-full" />
            </div>
        </div>
    );
};

export default ShareChart5;
