import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TableNo17() {
    // Data for the table and chart
    const data = [
        { category: 'Male', share2023: 68.01 },
    ];

    // Chart data
    const chartData = {
        labels: data.map(item => item.category),
        datasets: [
            {
                label: 'Share % (2023)',
                data: data.map(item => item.share2023),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Share % for Male in 2023',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: value => `${value}%`,
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto my-6 p-4">
            <h2 className="text-center text-2xl font-bold mb-4">Share % for Male in 2023</h2>

            {/* Responsive Table */}
            <div className="overflow-x-auto mb-8">
                <table className="min-w-full border border-gray-300 text-center">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Category</th>
                            <th className="px-4 py-2">Share % (2023)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index} className="border-b">
                                <td className="px-4 py-2">{row.category}</td>
                                <td className="px-4 py-2">{row.share2023}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Responsive Bar Chart */}
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
}
