// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// ChartJS.register(ArcElement, Tooltip, Legend);

// const TableNo11 = () => {
//   // Data for the table and chart
//   const raceData = [
//     { race: 'American Indian or Alaska Native Alone', share: 1.55 },
//     { race: 'Asian Alone', share: 5.19 },
//     { race: 'Black or African American Alone', share: 2.83 },
//     { race: 'Native Hawaiian or Other Pacific Islander Alone', share: 1.14 },
//     { race: 'Two or More Race Groups', share: 2.10 },
//     { race: 'White Alone', share: 87.17 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: raceData.map(item => item.race),
//     datasets: [
//       {
//         label: 'Employment Share %',
//         data: raceData.map(item => item.share),
//         backgroundColor: [
//           'rgba(255, 99, 132, 0.6)',
//           'rgba(54, 162, 235, 0.6)',
//           'rgba(255, 206, 86, 0.6)',
//           'rgba(75, 192, 192, 0.6)',
//           'rgba(153, 102, 255, 0.6)',
//           'rgba(255, 159, 64, 0.6)',
//         ],
//         borderColor: [
//           'rgba(255, 99, 132, 1)',
//           'rgba(54, 162, 235, 1)',
//           'rgba(255, 206, 86, 1)',
//           'rgba(75, 192, 192, 1)',
//           'rgba(153, 102, 255, 1)',
//           'rgba(255, 159, 64, 1)',
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Pie chart options
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Employment Share by Race</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Race</th>
//             <th>Employment Share %</th>
//           </tr>
//         </thead>
//         <tbody>
//           {raceData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.race}</td>
//               <td>{item.share.toFixed(2)}%</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Pie Chart */}
//       <div style={{ height: '400px', width: '100%' }}>
//         <Pie data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default TableNo11;





import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const TableNo11 = () => {
  // Data for the table and chart
  const raceData = [
    { race: 'American Indian or Alaska Native Alone', share: 1.55 },
    { race: 'Asian Alone', share: 5.19 },
    { race: 'Black or African American Alone', share: 2.83 },
    { race: 'Native Hawaiian or Other Pacific Islander Alone', share: 1.14 },
    { race: 'Two or More Race Groups', share: 2.10 },
    { race: 'White Alone', share: 87.17 },
  ];

  // Chart data
  const chartData = {
    labels: raceData.map(item => item.race),
    datasets: [
      {
        label: 'Employment Share %',
        data: raceData.map(item => item.share),
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // Pie chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
        },
      },
    },
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Employment Share by Race</h2>

      {/* Responsive Table */}
      <div className="table-responsive">
        <Table striped bordered hover responsive="md">
          <thead>
            <tr>
              <th>Race</th>
              <th>Employment Share %</th>
            </tr>
          </thead>
          <tbody>
            {raceData.map((item, index) => (
              <tr key={index}>
                <td>{item.race}</td>
                <td>{item.share.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Centered Pie Chart */}
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div style={{ height: '400px', width: '100%', maxWidth: '500px' }}>
          <Pie data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TableNo11;
