import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableNo34 = () => {
  // Data for the table and chart
  const ethnicityShareData = [
    { ethnicityLabel: 'Hispanic or Latino', share2018: 18.96, share2022: 20.00, share2023: 20.52 },
    { ethnicityLabel: 'Not Hispanic or Latino', share2018: 81.04, share2022: 80.00, share2023: 79.48 },
  ];

  // Chart data
  const chartData = {
    labels: ethnicityShareData.map(item => item.ethnicityLabel),
    datasets: [
      {
        label: 'Share % (2018)',
        data: ethnicityShareData.map(item => item.share2018),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Share % (2022)',
        data: ethnicityShareData.map(item => item.share2022),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Share % (2023)',
        data: ethnicityShareData.map(item => item.share2023),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`, // Display percentage on Y-axis
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}%`, // Show percentage in tooltip
        },
      },
    },
  };

  return (
    <div className="container">
      <h2>Ethnicity Share by Year (2018, 2022, 2023)</h2>

      {/* Responsive Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Ethnicity Label</th>
            <th>Share % (2018)</th>
            <th>Share % (2022)</th>
            <th>Share % (2023)</th>
          </tr>
        </thead>
        <tbody>
          {ethnicityShareData.map((item, index) => (
            <tr key={index}>
              <td>{item.ethnicityLabel}</td>
              <td>{item.share2018}%</td>
              <td>{item.share2022}%</td>
              <td>{item.share2023}%</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Bar Chart */}
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TableNo34;
